import React from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';

import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import BookingForm from '../components/Booking';
import ColourBlock from '../components/ColourBlock';
import ContentBlockHtml from '../components/ContentBlock';
import Cta from '../components/Ctas';
import FaqBlock from '../components/Faqs';
import Hero from '../components/Hero';
import Hires from '../components/HireTypes';
import ReviewsBlock from '../components/Reviews';

import '../styles/default-page.scss';
import '../styles/text.scss';

export interface Props {
	data: {
		wpPage: Page;
	};
	location: Location;
}

export const DefaultPage = (props: Props) => {
	const page           = props.data.wpPage;
	const showFaqHeading = page.title !== 'FAQs';
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null;
	const bannerFluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.bannerContentBlock &&
		page.bannerContentBlock.bannerContentImage &&
		page.bannerContentBlock.bannerContentImage.localFile &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData
	) ? page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData : null;
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	};
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="default">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Hero location={props.location} heroBanner={page.heroBanner} fluid={fluid}/>
							)}
							{(page.bookNowBlock && page.bookNowBlock.showBookNowBlock === 'yes') ? (
								<BookingForm location={props.location} isVehicle={true}/>
							) : ''}
						</div>
						{(page.content || page.title === 'FAQs') ? (
							<Row type="flex" className="c_text page-gutter">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<h1>{page.title}</h1>
									<div dangerouslySetInnerHTML={{__html: page.content}}/>
								</Col>
							</Row>
						) : ''}
						{(page.hireTypes && page.hireTypes.hireType && page.hireTypes.hireType.length) ? (
							<Hires location={props.location} hireTypes={page.hireTypes}/>
						) : ''}
						{(page.ctas && page.ctas.ctas && page.ctas.ctas.length) ? (
							<Cta location={props.location} ctas={page.ctas} welfare={false}/>
						) : ''}
						{(page.bannerContentBlock && page.bannerContentBlock.showBannerContentBlock === 'yes' && bannerFluid !== null) ? (
							<ColourBlock location={props.location} bannerContentBlock={page.bannerContentBlock} bannerFluid={bannerFluid}/>
						) : ''}
						{(page.reviews && page.reviews.showReviews === 'yes') ? (
							<ReviewsBlock location={props.location} reviews={page.reviews} settings={settings}/>
						) : ''}
						{(page.faqs && page.faqs.showFaqs === 'yes' && showFaqHeading) ? (
							<FaqBlock location={props.location} showHeading={showFaqHeading} faqs={page.faqs} bgWhite={false}/>
						) : ''}
						{(page.faqs && page.faqs.showFaqs === 'yes' && !showFaqHeading) ? (
							<div className="c_faqs__wrapper">
								<FaqBlock location={props.location} showHeading={showFaqHeading} faqs={page.faqs} bgWhite={false}/>
							</div>
						) : ''}
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock}/>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default DefaultPage;

export const query = graphql`
	query($id: String!) {
      wpPage(id: { eq: $id }) {
      	title
      	content
        slug
        id
        bookNowBlock {
          showBookNowBlock
        }
        bannerContentBlock {
          bannerContentBackgroundColour
          bannerContentContent
          bannerContentCtaLink
          bannerContentCtaText
          bannerContentShowCta
          bannerContentSubtitle
          bannerContentSubtitleColour
          bannerContentTitle
          bannerContentTitleColour
          fieldGroupName
          showBannerContentBlock
          bannerContentImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
              }
            }
          }
        }
        contentBlock {
          contentContent
          contentSubtitle
          contentTitle
          fieldGroupName
          showContentBlock
        }
        ctas {
          ctas {
            fieldGroupName
            ctaTitle
            ctaIcon {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
                }
              }
            }
            ctaLink {
              target
              title
              url
            }
          }
          fieldGroupName
        }
        faqs {
          faqs {
            question
            fieldGroupName
            answer
          }
          showFaqs
          fieldGroupName
        }
        heroBanner {
          bannerContent
          bannerLayout
          bannerTextColour
          fieldGroupName
          bannerTitle
          showBanner
          bannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
              }
            }
          }
        }
        hireTypes {
          fieldGroupName
          hireType {
            fieldGroupName
            hireContent
            typeOfHire
            hireLink {
              ... on WpPage {
                link
              }
            }
            hireImage {
              altText
              caption
              description
              fileSize
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
                }
              }
            }
          }
        }
        reviews {
          showReviews
          reviews {
            reviewContent
            reviewName
            reviewStars
          }
        }
        seo {
          metaDesc
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          title
          twitterTitle
          schema {
            articleType
            pageType
            raw
          }
          twitterDescription
          twitterImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
              }
            }
          }
        }
      }
    }
`;
